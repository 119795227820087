export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const SET_COLLECTION = 'SET_COLLECTION';
export const ADD_COLLECTION = 'ADD_COLLECTION';
export const COLLECTION_DELETED = 'COLLECTION_DELETED';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const COLLECTION_COUNT= 'COLLECTION_COUNT';


function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    console.log(response);
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {type: SET_COLLECTIONS, results}
}

export function setin(results) {
  return {type: SET_COLLECTION, results}
}

export function add(results) {
  return {type: ADD_COLLECTION , results}
}
export function count(results) {
  return {type: COLLECTION_COUNT, results}
}
export function del(tagID) {
  return {type: COLLECTION_DELETED, tagID}
}
export function authError(error) {
  return {type: COLLECTION_ERROR , payload: error};
}

export function fetchcollection(data) {
  return dispatch => {
    fetch('/api/fotoboek/collection', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(set(data.results))).catch(err => dispatch(authError(err)));
  };
}

export function deletecollection(id) {
  return dispatch => {
    return fetch(`/api/fotoboek/tags/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(data => dispatch(del(id)));
  }
}

export function setcollection(item) {
  return dispatch => {
    return fetch(`/api/fotoboek/collection/${item}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(set(data.results))).catch(err => dispatch(authError(err)));
  };
}
