import {SET_LEDEN, ADD_LEDEN, LEDEN_DELETED,SET_LEDEN_COUNT} from './actions';

export default function tags(state = {
    total: [],
    leden: [],
    add: []
}, action = {}) {
  switch (action.type) {
    case ADD_LEDEN:
        return {
        ...state,
        add: action.results
      };
    case LEDEN_DELETED:
      var ret =  state.leden.filter(item => item._id !== action.tagID);
      return {
        ...state,
        leden: ret
      };
    case SET_LEDEN:
    return {
      ...state,
      leden: action.results
    };
    case SET_LEDEN_COUNT:
    return {
      ...state,
      total: action.results
    };
    default:
      return state;
  }
}
