import { combineReducers } from 'redux';


import cats from './reducers/categorie/categorie.js';
import doelgroepen from './reducers/doelgroepen/doelgroepen.js';
import auth from './reducers/auth/reducers.js';
import activiteiten from './reducers/activiteiten/activiteiten.js';
import bedrijven from './reducers/bedrijven/bedrijven.js';
import profile from './reducers/profile/profile.js';
import tags from './reducers/fotoboek/tags/tags.js';
import themes from './reducers/fotoboek/themas/themas.js';
import upload from './reducers/fotoboek/upload/upload.js';
import fotos from './reducers/fotoboek/fotos/fotos.js';
import collection from './reducers/fotoboek/collection/collection.js';
import leden from './reducers/leden/leden.js';

export default combineReducers({
  cats,
  doelgroepen,
  auth,
  activiteiten,
  bedrijven,
  profile,
  tags,
  themes,
  upload,
  fotos,
  leden,
  collection
});
