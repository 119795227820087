import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../reducers/fotoboek/themas/actions.js";
import Select from "react-select";
class Themas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: this.props.name,
    };
    this.props.GetTheme(this.props.name);
  }
  componentDidMount() {
    this.props.fetchthemas();
  }
  updateState(element) {
    this.setState({ themes: element });
    this.props.GetTheme(element.map(GetValue));
  }
  render() {
    return (
      <div className="form-group">
        <label>Thema</label>
        <Select
          name="categorien"
          options={this.props.themes}
          class="form-control"
          getOptionLabel={opt => opt.naam}
          getOptionValue={opt => opt._id}
          isMulti
          value={this.state.themes}
          onChange={this.updateState.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { themes: state.themes };
}
function GetValue(item, index) {
  var fullname = [item._id].join(" ");
  return fullname;
}

export default connect(mapStateToProps, actions)(Themas);
