import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Error from '../../Components/Widgets/error'
import * as actions from "../../reducers/leden/actions.js";
import "../../lib/css/plugins/datatable/style.css";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs");

class LedenPage extends React.Component {
  componentWillMount() {
    this.props.fetchleden();
  }
  componentDidUpdate() {
    $(this.refs.tabeltags).DataTable();
  }
  onDelete(leden, event) {
    event.preventDefault();
    this.props.deletelid(leden._id);
  }
  render() {
    const { leden, token } = this.props;
    const isregist = lid => {
      if (token.role === "admin") {
        if (lid.isregist.length > 0) {
          return <span className="label label-success">Heeft account</span>;
        }
      }
    };
    const buttonnew =() => {
      if (token.role === "admin") {
          return (      <div className="box-tools">
                  <Link className="btn btn-block btn-primary" to="/leden/new">
                    Nieuw
                  </Link>
                </div>)

      }

    }
    const beeldbankadmin = app => {
      if (token.role === "admin") {
        return (
          <span>
            <button
              className="btn regular blue"
              onClick={this.onDelete.bind(this, app)}
            >
              <i className="fa fa-fw fa-remove" />
            </button>
          </span>
        );
      }
    };
    if (leden && leden.length > 0) {
      return (
        <div className="box">
          <div className="box-header">
            <h3 className="box-title">Lijst met Leden</h3>

      {buttonnew()}
          </div>
          <div className="box-body">
            <table
              ref="tabeltags"
              className="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <td>Naam</td>
                  <td>Email</td>
                  <th>Plaats</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {leden.map(app => (
                  <tr key={app._id}>
                    <td>
                      <Link to={"/leden/lid/" + app._id}>{app.naam}</Link>
                    </td>
                    <td>{app.email}</td>
                    <td>{app.plaats}</td>
                    <td>{isregist(app)}</td>
                    <td>{beeldbankadmin(app)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Error message="Er zijn geen leden ingevoerd"/>
    }
  }
}

function mapStateToProps(state) {
  return { leden: state.leden.leden, token: state.auth.token };
}

export default connect(
  mapStateToProps,
  actions
)(LedenPage);
