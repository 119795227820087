import React, {Component} from 'react';
import Header from './Themes/Main/Header.jsx';
import Sitebar from './Themes/Main/Sitebar.jsx';
import Footer from './Themes/Main/Footer.jsx';
import {Route} from 'react-router-dom';
import Startpage from './pages/';
import LoginPage from './Components/Login/Login.jsx';
import ForgetPage from './Components/Login/Forget'
import SignupPage from './Components/SignUp/Singup.jsx';
import VerficationPage from './Components/SignUp/Confirmation.jsx';
import NewPasswordPage from './Components/Login/NewPassword'
import RequireAuth from './auth/require_auth';
import ProfilePage from './pages/profile/profile'
import TagsPage from './pages/fotoboek/tags';
import TagsPageInsert from './pages/fotoboek/tags/insert';
import ThemaPage from './pages/fotoboek/themas';
import ThemaPageInsert from './pages/fotoboek/themas/insert';
import UploadPage from './pages/fotoboek/upload/'
import FotoPage from './pages/fotoboek/fotos/'
import FotoPageAdmin from './pages/fotoboek/fotos/admin'
import Collections from './pages/fotoboek/fotos/collections'
import Downloads from './pages/fotoboek/fotos/downloads'
import FotoPageItem from './pages/fotoboek/fotos/item'
import FotoPageItemAdmin from './pages/fotoboek/fotos/itemadmin'
import LedenPage from './pages/leden/leden'
import LidPage from './pages/leden/lid'
import LedenInsertPage from './pages/leden/insert'
import LogoStart from './pages/toolkit/logo'
import Kleurenpalet from "./pages/toolkit/kleurenpalet"
import SocialPage from "./pages/toolkit/social"
import TekstenPage from "./pages/toolkit/teksten"
import JaarplanPage from "./pages/epewild/jaarplannen"
import BijeenkostPage from "./pages/epewild/bijeenkomsten"
import VideoBookStart from './pages/videobook/index'
class App extends Component {

  render() {
    const {location} = this.props;
    let loc = location.pathname.split('/');
    if (loc[1] === 'login' || loc[1] === 'signup') {
      return (<div className="content-box">
        <Route exact path="/login" component={LoginPage} {...App}/>
        <Route exact path="/signup" component={SignupPage}/>
        <Route exact path="/login/forget" component={ForgetPage}/>
        <Route exact path="/login/forget/:code" component={NewPasswordPage}/>
        <Route exact path="/signup/verify/:code" component={VerficationPage}/>
      </div>)
    } else {
      return (<div className="content-page">
        <Header></Header>
        <Sitebar/>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content">
            <Route exact path="/admin/profile" component={RequireAuth(ProfilePage)}/>
            <Route exact path="/beeldbank/tags" component={RequireAuth(TagsPage)}/>
            <Route path="/beeldbank/tags/new" component={RequireAuth(TagsPageInsert)}/>
            <Route exact path="/beeldbank/themas" component={RequireAuth(ThemaPage)}/>
            <Route path="/beeldbank/themas/new" component={RequireAuth(ThemaPageInsert)}/>
            <Route exact path="/beeldbank/upload" component={RequireAuth(UploadPage)}/>
            <Route exact path="/beeldbank/foto" component={RequireAuth(FotoPage)}/>
            <Route exact path="/beeldbank/collections" component={RequireAuth(Collections)}/>
            <Route exact path="/beeldbank/downloads" component={RequireAuth(Downloads)}/>
            <Route exact path="/beeldbank/item/:item" component={RequireAuth(FotoPageItem)}/>
            <Route exact path="/leden/" component={RequireAuth(LedenPage)}/>
            <Route exact path="/leden/new" component={RequireAuth(LedenInsertPage)}/>
            <Route exact path="/leden/lid/:id" component={RequireAuth(LidPage)}/>
            <Route exact path="/" component={RequireAuth(Startpage)}/>
            <Route exact path="/toolkit/logo" component={RequireAuth(LogoStart)}/>
            <Route exact path="/toolkit/kleurenpalet" component={RequireAuth(Kleurenpalet)}/>
            <Route exact path="/toolkit/social" component={RequireAuth(SocialPage)}/>
            <Route exact path="/toolkit/teksten" component={RequireAuth(TekstenPage)}/>
            <Route exact path="/admin/beeldbank/" component={RequireAuth(FotoPageAdmin)}/>
            <Route exact path="/admin/beeldbank/item/:item" component={RequireAuth(FotoPageItemAdmin)}/>
            <Route exact path="/epewild/jaarplan" component={RequireAuth(JaarplanPage)}/>
            <Route exact path="/epewild/bijeenkomsten" component={RequireAuth(BijeenkostPage)}/>
            <Route exact path="/beeldbank/video" component={RequireAuth(VideoBookStart)}/>
          </section>
        </div>
        <Footer/>
      </div>);
    }
  }
}

export default App;
