import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../reducers/fotoboek/themas/actions.js';
import '../../../lib/css/plugins/datatable/style.css';
const $ = require('jquery');
$.DataTable = require('datatables.net-bs');

class themasPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: ''
    }
  }

  componentWillMount() {
    this.props.fetchthemas();
  }
  componentDidUpdate() {
    $(this.refs.tabelthemas).DataTable();
  }
  onDelete(themes, event) {
    event.preventDefault()
    this.props.deletethema(themes._id);
  }
  render() {
    let errormsg = null
    if (this.props.themes.length === 0) {
      errormsg = <p>Er zijn geen themas ingevoerd</p>
    }
    const {themes} = this.props;
    return (
      <div className="box">
        <div className="box-header">
          <h3 className="box-title">Lijst met themas</h3>

          <div className="box-tools">
            <Link className="btn btn-block btn-primary" to="/beeldbank/themas/new">Nieuw</Link>
          </div>
        </div>
        {errormsg}
        <div className="box-body">
          <table ref="tabelthemas" className="table table-striped table-bordered">
            <thead>
              <tr>
                <td>Titel</td>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {themes.map((app) => (
                <tr key={app._id}>
                  <td>{app.naam}</td>
                  <td>
                    <button className="btn regular blue" onClick={this.onDelete.bind(this, app)}><i className="fa fa-fw fa-remove"/></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {themes: state.themes}
}

export default connect(mapStateToProps, actions)(themasPage);
