import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Login,authError} from '../../reducers/auth/actions';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: ''
    }
    this.onChange = this.onChange.bind(this);
  }
  userLogin(e) {
    e.preventDefault();
    this.props.Login({"email": this.state.email, "password": this.state.password, "strategy": "local"}).then((whatever) => {
      if(whatever) {
        this.props.history.push('/');
        window.location.reload();
      }
    });
  }
  onChange(event) {
    this.props.authError(null);
    const field = event.target.name;
    const credentials = this.state;
    credentials[field] = event.target.value;
    return this.setState({credentials: credentials});
  }
  componentWillMount() {
    this.props.authError(null);
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong>
          {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {

    return (

    <div className="content-page">
      <div className="content-wrapper fullscreen transpbackground">
      <div className="login-box">
        <div className="login-logo">
          <b>#EpeWild</b>
        </div>
        {/* /.login-logo */}
        <div className="login-box-body">
          <p className="login-box-msg">Vul je gegevens in om in te loggen</p>
                {this.renderAlert()}
          <form method="post">
            <div className="form-group has-feedback">
              <input value={this.state.email} onChange={this.onChange} type="email" name="email" className="form-control" placeholder="Email"/>

            </div>
            <div className="form-group has-feedback">
              <input value={this.state.password} onChange={this.onChange} type="password" name="password" className="form-control" placeholder="Password"/>
            </div>
            <div className=" form-group has-feedback row">
              <div className="col-xs-8">
              </div>
              {/* /.col */}
              <div className="col-xs-4">
                <button type="submit" onClick={(e) => this.userLogin(e)} className="btn btn-primary btn-block btn-flat">Inloggen</button>
              </div>
            </div>
          </form>
          <div className="social-auth-links text-center">
      <Link to="/signup" className="btn btn-block btn-social btn-wild btn-flat"><i className="fa fa-gear"></i> Maak een account</Link>
    </div>
          <Link to="/login/forget">Wachtwoord vergeten</Link><br/>
        </div>
      </div>
    </div>
      <footer className="main-footer fullscreen wild-logo ">
        <div className="footer-text pull-right hidden-xs">
          <b>Version</b> 1.0.0
        </div>
        <strong>Copyright © 2023 <a href="https://wwwappz.nl">WwappZ Studio</a>.</strong> All rights
        reserved.
      </footer>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {errorMessage: state.auth.error};
}

export default connect(mapStateToProps, {Login,authError})(LoginPage);
