import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { downloadfile } from "../../reducers/fotoboek/fotos/actions.js";
class BijeenkostPage extends React.Component {
  download(file, e) {
    var data = {
      filename: file[0],
      file: file[1],
    };
    this.props.downloadfile(data);
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-9">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Bijeenkomsten</h3>
            </div>
            {/* /.box-header */}
            <div className="box-body">
              <dt>
                Op deze pagina vindt u bijeenkomsten die georganiseerd zijn door
                Stichting Promotie Gemeente Epe
              </dt>
            </div>
            {/* /.box-body */}
          </div>
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">
           Ondernemersbijeenkomst van 16 november 2023.
              </h3>
            </div>
            {/* /.box-header */}
            <div className="box-body darkgreenbackground">
              <p>
            Op 16 november 2023 organiseerde Stichting Promotie Gemeente Epe een bijeenkomst voor ondernemers. Hierbij de twee presentaties die gegeven zijn.
              </p>
              <ol>
                <li>Presentatie Stichting Promotie Gemeente Epe</li>
                <li>Presentatie de Belgische gast</li>
             
              </ol>

              <button
                onClick={this.download.bind(this, [
                  "Ledenbijeenkomst 16 november.pptx",
                  "Ledenbijeenkomst 16 november",
                ])}
                type="button"
                className="btn btn-primary btn-mg"
              >
                Presentatie Stichting Promotie Gemeente Epe
              </button>
              &nbsp;
              <button
                onClick={this.download.bind(this, [
                  "2023.11.16 Presentatie Belgische gast.pptx",
                  "2023.11.16 Presentatie Belgische gast",
                ])}
                type="button"
                className="btn btn-primary btn-mg"
              >
                Presentatie Belgische gast
              </button>
            </div>
          </div>
<hr/>
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">
                Info n.a.v. Ondernemersbijeenkomst van 28 november 2018.
              </h3>
            </div>
            {/* /.box-header */}
            <div className="box-body ">
              <p>
                Met 30 aanwezigen kunnen we terugblikken op een mooie
                informatieve avond. De presentaties zijn in te zien via het
                ledenportaal www.epewild.nl.
              </p>
              <ol>
                <li>Presentatie Stichting Promotie Gemeente Epe</li>
                <li>Presentatie social media door Danny Steigerwald</li>
                <li>
                  Daarnaast willen we graag jullie input over drie vragen m.b.t.
                  'VVV in beweging'.
                </li>
              </ol>
              <p>
                Via de link komen jullie in een enqu&ecirc;te-omgeving.
                <br />
                Wij hopen dat jullie even 5 minuten tijd nemen om deze drie
                vragen te beantwoorden. Dat helpt ons verder in het bepalen van
                onze taken en prioriteiten.
              </p>
              <p>T.z.t. zullen we de uitkomsten met jullie delen.</p>
              <Link
                className="btn btn-primary btn-mg"
                target="_blank"
                to="https://docs.google.com/forms/d/e/1FAIpQLSdYBxKs_FR06h4Ueley6wXV8TkcLxYnEeZ2wR_mH3cHfGJLzQ/viewform"
              >
                Enqu&ecirc;te
              </Link>
              &nbsp;
              <button
                onClick={this.download.bind(this, [
                  "Bijeenkomst 28 nov 2018 De Bosrand.pdf",
                  "Presentatie Stichting Promotie Gemeente Epe",
                ])}
                type="button"
                className="btn btn-primary btn-mg"
              >
                Presentatie Stichting Promotie Gemeente Epe
              </button>
              &nbsp;
              <button
                onClick={this.download.bind(this, [
                  "Presentatie 20181128.pdf",
                  "Presentatie social media",
                ])}
                type="button"
                className="btn btn-primary btn-mg"
              >
                Presentatie social media{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return true;
}

export default connect(mapStateToProps, { downloadfile })(BijeenkostPage);
