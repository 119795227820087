import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../reducers/leden/actions.js';
class LedenWidget extends Component {
  componentWillMount() {
    this.props.fetchledencount();
  }
  render() {
    const {total} = this.props;
    return (
      <div className="small-box bg-aqua">
        <div className="inner">
          <h3>{total.count}</h3>
          <p>
            Leden
          </p>
        </div>
        <div className="icon">
          <i className="fa fa-users"></i>
        </div>
        <Link to="/admin/profile" className="small-box-footer">
        Ga naar je profiel <i className="fa fa-arrow-circle-right"/>
      </Link>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {total: state.leden.total}
}

export default connect(mapStateToProps, actions)(LedenWidget);
