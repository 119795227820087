import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../reducers/fotoboek/fotos/actions.js';
import Tags from '../../../Components/Fotoboek/form/tags.jsx';
import Themas from '../../../Components/Fotoboek/form/themas.jsx';
import Loading from '../../../Components/loading/loading.jsx';
class FotoItem extends React.Component {

  constructor() {
    super();
    this.state = {
      errors: {},
      eigenaar: '',
      beschrijving: ' ',
      ok: '',
      tags: [],
      themes: []
    }
  }

  componentWillMount() {
    this.props.fetchfotosItem(this.props.match.params.item);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fotos) {
      this.setState({beschrijving: nextProps.fotos.beschrijving})
      this.setState({eigenaar: nextProps.fotos.eigenaar})
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  GetTags = (event) => {
    console.log(event);
    this.setState({tags: event});
  }

  GetTheme = (event) => {
    this.setState({themes: event});
  }

  Download = (e) => {
    this.props.download(this.props.match.params.item);
  }
  DeleteFoto = (e) => {
    this.props.deletefoto(this.props.match.params.item).then((data) => {
      this.props.history.replace({pathname: '/admin/beeldbank'})
    });

  }
  handleSubmit = (e) => {
    e.preventDefault();
    let errors = {},
      ok = {};
    const {eigenaar, beschrijving, tags, themes} = this.state;
    this.props.updatefotoItem({
      eigenaar,
      beschrijving,
      tags,
      themes
    }, this.props.match.params.item).then((data) => {
      if (data.type === "TAGS_ERROR") {
        errors.mes = "Error! De gegeven zijn niet opgeslagen. Ben je offline?";
        this.setState({errors});
        return true
      } else {
        this.props.fetchfotosItem(this.props.match.params.item);
        ok.mes = "Goed.. De gegevens zijn opgeslagen";
        this.setState({ok});
      }
    });
  }

  render() {
    const {fotos} = this.props;
    if (fotos._id === this.props.match.params.item) {
      return (
        <div className="container">
          {!!this.state.errors.mes && <div className="alert alert-danger alert-dismissible">
            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h4>
              <i className="icon fa fa-ban"></i>{this.state.errors.mes}
            </h4>
          </div>
}
          {!!this.state.ok.mes && <div className="alert alert-success alert-dismissible">
            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h4>
              <i className="icon fa fa-ban"></i>{this.state.ok.mes}
            </h4>
          </div>
}
          <div className="row">
            <div className="col-sm-8">
            <img alt="foto van epe" src={fotos.thumbnail[0]['720']} width="100%"/>
            </div>
            <div className="col-sm-4">
              <form onSubmit={this.handleSubmit}>
                <h2>Details</h2>
                <div className="form-group">
                  <label htmlFor="titel">Beschrijf de foto</label>
                  <textarea rows="5" value={this.state.beschrijving || ''} name="beschrijving" onChange={this.handleChange} id="beschrijving" className="form-control"/>
                </div>
                <div className="form-group">
                  <label htmlFor="titel">Eigenaar</label>
                  <input type="text" className="form-control" value={this.state.eigenaar || ''} onChange={this.handleChange} name="eigenaar" id="eigenaar" placeholder="Vul de eigenaar in"/>
                </div>
                <Tags name={fotos.tags} GetTags={this.GetTags}/>
                <Themas name={fotos.themes} GetTheme={this.GetTheme}/>
                <button type="submit" className="btn btn-block btn-primary btn-lg">Opslaan</button>
              <button type="button" className="btn btn-block btn-warning btn-lg" onClick={this.DeleteFoto}>Verwijder foto</button>
              </form>

            </div>
          </div>
        </div>
      );
    } else {
      return (
        <span>
          <Loading/>
        </span>
      )
    }
  }
}
function mapStateToProps(state) {
  return {fotos: state.fotos.item}
}

export default connect(mapStateToProps, actions)(FotoItem);
