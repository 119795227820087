import React from 'react';

import {connect} from 'react-redux';
import * as actions from '../../../reducers/fotoboek/fotos/actions.js';
import Loading from '../../../Components/loading/loading.jsx';
class FotoItem extends React.Component {

  constructor() {
    super();
    this.state = {
      errors: {},
      eigenaar: '',
      beschrijving: ' ',
      ok: '',
      tags: [],
      themes: []
    }
  }

  componentWillMount() {
    this.props.fetchfotosItem(this.props.match.params.item);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fotos) {
      this.setState({beschrijving: nextProps.fotos.beschrijving})
      this.setState({eigenaar: nextProps.fotos.eigenaar})
    };
  }

  Download = (e) => {
    this.props.download(this.props.match.params.item);
  }


  render() {
    const {fotos} = this.props;
    if (fotos._id === this.props.match.params.item) {
      return (
          <div className="row">
            <div className="col-sm-8">
              <img alt="Foto van Logo" src={fotos.thumbnail[0]['720']} width="100%"/>
            </div>
            <div className="col-sm-4">
                <h2>Details</h2>
                <div className="form-group">
                  <label htmlFor="titel">Beschrijf de foto</label>
                <p>{this.state.beschrijving || ''} </p>
                </div>
                <div className="form-group">
                  <label htmlFor="titel">Eigenaar</label>
                <p>{this.state.eigenaar || ''}</p>
                </div>

              <button type="button" onClick={this.Download.bind(this)} className="btn btn-block btn-primary btn-lg">Download</button>
          </div>
        </div>
      );
    } else {
      return (
        <span>
          <Loading/>
        </span>
      )
    }
  }
}
function mapStateToProps(state) {
  return {fotos: state.fotos.item}
}

export default connect(mapStateToProps, actions)(FotoItem);
