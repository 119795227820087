import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as actions from "../../reducers/leden/actions.js";
import { connect } from "react-redux";
class LidPage extends Component {

  state = {
    errors: {},
    lid: ""
  };

  componentDidMount() {
    const { leden } = this.props;
    let id = this.props.match.params.id;
    if (leden && leden.length > 0) {
      // update the state to the correct league here and this.props.id is the id that you want
      const lid = leden.find(aLid => aLid._id === id);

      this.setState({
        lid
      });
    } else {
      this.props.history.push("/leden");
    }
  }
  render() {
    const { lid } = this.state;
    return (
      <div className="box box-widget">
        <div className="box-header with-border">
        <Link type="button" to="/leden" className="btn btn-primary">Terug</Link>
          <div className="user-block">
            <h3>{lid.naam}</h3>
            <h5 className="description">{lid.type}</h5>
          </div>
          {/* /.user-block */}
          <div className="box-tools" />
          <div className="user-block">
            <div className="box-header with-border">
              <h4>Contactinformatie</h4>
              <table className="table">
                      <tbody><tr>
                        <th className="tablerow30">Contactpersoon</th>
                        <td>{lid.contactpersoon}</td>
                      </tr>
                      <tr>
                        <th>Adres</th>
                        <td>{lid.adres}</td>
                      </tr>
                      <tr>
                        <th>Plaats</th>
                        <td>{lid.plaats}</td>
                      </tr>
                      <tr>
                        <th>Emailadres</th>
                        <td>{lid.email}</td>
                      </tr>
                    </tbody></table>
            </div>
          </div>
          {/* /.box-tools */}
        </div>
        {/* /.box-header */}
        <div className="box-body" />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { leden: state.leden.leden, token: state.auth.token };
}

export default connect(
  mapStateToProps,
  actions
)(LidPage);
