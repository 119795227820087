import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../reducers/fotoboek/tags/actions.js";
import Select from "react-select";
class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.name,
    };
    this.props.GetTags(this.props.name);
  }
  componentDidMount() {
    this.props.fetchtags();
  }
  updateState(element) {
    console.log(element);
    this.setState({ tags: element });
    this.props.GetTags(element.map(getFullName));
  }
  render() {
    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };
    const groupBadgeStyles = {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center",
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
    return (
      <div className="form-group">
        <label>Tags</label>
        <Select
          formatGroupLabel={formatGroupLabel}
          name="tags"
          options={this.props.tags}
          class="form-control"
          getOptionLabel={opt => opt.naam}
          getOptionValue={opt => opt._id}
          isMulti
          value={this.state.tags}
          onChange={this.updateState.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { tags: state.tags };
}
function getFullName(item, index) {
  var fullname = [item._id].join(" ");
  return fullname;
}

export default connect(mapStateToProps, actions)(Tags);
