import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../reducers/fotoboek/upload/actions.js";
import Dropzone from "react-dropzone";

class FotoboekUploadPage extends React.Component {
  constructor() {
    super();
    this.state = {
      files: [],
    };
  }
  onDrop(files) {
    this.setState({ files });
  }

  uploaden = (e) => {
    let errors = {};
    const files = this.state.files;
    files.forEach((file, index) => {
      this.props.upload({ file }).then((data) => {
        if (data.errors) {
          errors.mes = "foutje";
          this.setState({ errors });
          return true;
        } else {
          this.removeItem(index);
          this.props.history.replace({
            pathname: "/admin/beeldbank/item/" + data.results._id,
          });
        }
      });
    });
  };
  removeItem(index) {
    this.setState({
      files: this.state.files.filter((_, i) => i !== index),
    });
  }
  render() {
    return (
      <div className="box">
        <div className="box-header">
          <h3 className="box-title">Upload hier de foto's</h3>
        </div>

        <div className="box-body">
          <div className="dropzone">
            <Dropzone
              accept="image/jpeg"
              multiple={false}
              onDrop={this.onDrop.bind(this)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <h2>Dropped files</h2>
          <div className="row">
            {this.state.files.map((f, x) => (
              <div className="col-md-4" key={f.name}>
                <div className="small-box bg-red">
                  <img src={f.preview} width="100%" alt="User foto" />
                  <button
                    onClick={this.removeItem.bind(this, x)}
                    className="small-box-footer"
                  >
                    Verwijderen
                    <i className="fa fa-close"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="box-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.uploaden.bind()}
          >
            Uploaden
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { upload: state.upload };
}

export default connect(mapStateToProps, actions)(FotoboekUploadPage);
