import React from 'react';
import {connect} from 'react-redux';
import {downloadfile} from '../../reducers/fotoboek/fotos/actions.js';
class SocialPage extends React.Component {
  download(file, e) {
    var data = {
      filename: file[0],
      file: file[1]
    }
    this.props.downloadfile(data);

  }
  render() {
    return (<div className="row">
      <div className="col-md-9">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Facebook Post</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <dt>Het is heel belangrijk dat alle Facebook posts consistent zijn met de huisstijl en samen één geheel vormen.
Wij hebben 6 facebook posts voor u klaar gezet die u zo kunt overnemen. Sommige posts zijn seizoensgebonden,
houdt daar rekening mee.
</dt>
            <p className="text-green">Elke post bestaat uit de volgende elementen:
              <ul>
            <li> Max. 3 regels tekst </li>
          <li> Daaronder de afbeelding (verhouding 4:3).</li>
        <li> Het logo in de groene balk met het hert.</li>
          </ul></p>
          </div>
          {/* /.box-body */}
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Verhouding</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
              <div className="col-md-12">
          <img src="/100wild/facebook/facebook_format.jpg" alt="Formaat"/>
      </div>
        <div className="col-md-6">
          <div className="box-header with-border">
            <h3 className="box-title">1) Bronsttijd, vanaf half september</h3>
          </div>
            <img src="/100wild/facebook/facebook.jpg" alt="Facebook"/>
          </div>
          <div className="col-md-6">
            <div className="box-header with-border">
              <h3 className="box-title">1) Wildmenu, vanaf oktober</h3>
            </div>
            <img src="/100wild/facebook/facebook_wild.jpg" alt="Wild"/>
          </div>
          </div>
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Diverse Posten</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <div className="col-md-6">
            <img src="/100wild/facebook/post1.jpg" alt="Facebook Post"/>
            </div>
            <div className="col-md-6">
              <img src="/100wild/facebook/post2.jpg" alt="Facebook Post"/>
            </div>
            <div className="col-md-6">
              <img src="/100wild/facebook/post3.jpg" alt="Facebook Post"/>
            </div>
            <div className="col-md-6">
              <img src="/100wild/facebook/post4.jpg" alt="Facebook Post"/>
            </div>

          </div>
          <div className="box-footer">
            <button onClick={this.download.bind(this,["FB-posts.zip", "Facebook Post Bestanden"])}  type="button" className="btn  btn-primary btn-sm">Download Bestanden</button>
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      {/* ./col */}
      <div className="col-md-3">
        <div className="box box-solid">
          {/* /.box-header */}
          <div className="box-body">
            <img alt=" logo 100% wild" src="/100wild/1x/Middel 1.png"/>
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      {/* ./col */}
    </div>);
  }
}

function mapStateToProps(state) {

}

export default connect(mapStateToProps, {downloadfile})(SocialPage);
