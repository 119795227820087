import React from 'react';
import {connect} from 'react-redux';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import * as actions from '../../reducers/profile/actions.js';

class ProfilePage extends React.Component {
  state = {
    errors: {}
  }

  componentWillMount() {
    this.props.fetchprofile();
  }
  handleClick(e) {
    var inputField = this.refs.fileUploader;
    inputField.click();
}
  handleUpload = (e) => {
    e.preventDefault();
    let errors = {};
    const file = e.target.files[0];
    this.props.upload_profile_image({file}).then((data) => {
      if (data.errors) {
        errors.mes = "foutje";
        this.setState({errors});
        return true
      } else {
        this.props.history.replace({pathname: '/admin/profile'})
      }
    });
  }

  render() {
    const {username, profile} = this.props
    const profileimage = (profile) => {
        if (profile) {
      return     <img className="profile-user-img img-responsive img-circle" src={profile.portimage}  alt="User profile foto"/>
      } else {
          return     <img src="/img/nofoto.jpg"  className="profile-user-img img-responsive img-circle" alt="User foto"/>
      }
    }
    return (
      <div className="row">
        <div className="col-md-3">
          {/* Profile Image */}
          <div className="box box-primary">
            <div className="box-body box-profile">
              <span onClick={this.handleClick.bind(this)}>
              {profileimage(profile)}
              <input type="file" name="files" accept=".jpg,.jpeg" onChange={this.handleUpload.bind(this)} ref="fileUploader" style={{display: "none"}}/>

          </span>
              <h3 className="profile-username text-center">
                {username.name}
              </h3>
              <input type="file" name="files" accept=".jpg,.jpeg" onChange={this.handleUpload.bind(this)} ref="fileUploader" style={{display: "none"}}/>


            </div>
            {/* /.box-body */}
          </div>

        </div>
        {/* /.col */}
        <div className="col-md-9 no-float">
          <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
              <li className="active">
                <a href="#activity" data-toggle="tab">Tweets</a>
              </li>

            </ul>
            <div className="tab-content">
              <div className="active tab-pane" id="activity">
                {/* Post */}
                <div className="centerContent">
      <div className="selfCenter standardWidth">
      <TwitterTimelineEmbed sourceType="profile" screenName="vvvepe" options={{height: 800}} />
      </div>
      </div>
                {/* /.post */}
              </div>
              {/* /.tab-pane */}
              <div className="tab-pane" id="timeline">
                {/* The timeline */}
                <ul className="timeline timeline-inverse">
                  {/* timeline time label */}
                  <li className="time-label">
                    <span className="bg-red">
                      10 Feb. 2014
                    </span>
                  </li>
                  {/* /.timeline-label */}
                  {/* timeline item */}
                  <li>
                    <i className="fa fa-envelope bg-blue"/>
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fa fa-clock-o"/>
                        12:05
                      </span>
                      <h3 className="timeline-header">
                        <a href="#">
                          Support Team
                        </a>
                        sent you an email
                      </h3>
                      <div className="timeline-body">
                        Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra. Babblely odeo kaboodle quora plaxo ideeli hulu weebly balihoo...
                      </div>
                      <div className="timeline-footer">
                        <a className="btn btn-primary btn-xs">
                          Read more
                        </a>
                        <a className="btn btn-danger btn-xs">Delete</a>
                      </div>
                    </div>
                  </li>
                  {/* END timeline item */}
                  {/* timeline item */}
                  <li>
                    <i className="fa fa-user bg-aqua"/>
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fa fa-clock-o"/>
                        5 mins ago
                      </span>
                      <h3 className="timeline-header no-border">
                        <a href="#">
                          Sarah Young
                        </a>
                        accepted your friend request
                      </h3>
                    </div>
                  </li>
                  {/* END timeline item */}
                  {/* timeline item */}
                  <li>
                    <i className="fa fa-comments bg-yellow"/>
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fa fa-clock-o"/>
                        27 mins ago
                      </span>
                      <h3 className="timeline-header">
                        <a href="#">
                          Jay White
                        </a>
                        commented on your post
                      </h3>
                      <div className="timeline-body">
                        Take me to your leader! Switzerland is small and neutral! We are more like Germany, ambitious and misunderstood!
                      </div>
                      <div className="timeline-footer">
                        <a className="btn btn-warning btn-flat btn-xs">
                          View comment
                        </a>
                      </div>
                    </div>
                  </li>
                  {/* END timeline item */}
                  {/* timeline time label */}
                  <li className="time-label">
                    <span className="bg-green">
                      3 Jan. 2014
                    </span>
                  </li>
                  {/* /.timeline-label */}
                  {/* timeline item */}
                  <li>
                    <i className="fa fa-camera bg-purple"/>
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fa fa-clock-o"/>
                        2 days ago
                      </span>
                      <h3 className="timeline-header">
                        <a href="#">
                          Mina Lee
                        </a>
                        uploaded new photos
                      </h3>
                      <div className="timeline-body">
                        <img src="http://placehold.it/150x100" alt="..." className="margin"/>
                        <img src="http://placehold.it/150x100" alt="..." className="margin"/>
                        <img src="http://placehold.it/150x100" alt="..." className="margin"/>
                        <img src="http://placehold.it/150x100" alt="..." className="margin"/>
                      </div>
                    </div>
                  </li>
                  {/* END timeline item */}
                  <li>
                    <i className="fa fa-clock-o bg-gray"/>
                  </li>
                </ul>
              </div>
              {/* /.tab-pane */}
              <div className="tab-pane" id="settings">
                <form className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="inputName" className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                      <input type="email" className="form-control" id="inputName" placeholder="Name"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEmail" className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                      <input type="email" className="form-control" id="inputEmail" placeholder="Email"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName" className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="inputName" placeholder="Name"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputExperience" className="col-sm-2 control-label">Experience</label>
                    <div className="col-sm-10">
                      <textarea className="form-control" id="inputExperience" placeholder="Experience"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputSkills" className="col-sm-2 control-label">Skills</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="inputSkills" placeholder="Skills"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <div className="checkbox">
                        <label>
                          <input type="checkbox"/>
                          I agree to the
                          <a href="#">terms and conditions</a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-danger">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
              {/* /.tab-pane */}
            </div>
            {/* /.tab-content */}
          </div>
          {/* /.nav-tabs-custom */}
        </div>
        {/* /.col */}
      </div>

    );
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, bedrijven: state.bedrijven.item, profile: state.profile};
}

export default connect(mapStateToProps, actions)(ProfilePage);
