import React from 'react';
import { connect } from 'react-redux';
import { downloadfile } from '../../reducers/fotoboek/fotos/actions.js';
import { ControlledPopup } from '../../Components/Main/Pop-up';

class VideoBookStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { overlay: false };
    }
  
    download(file, e) {
        let currentComponent = this;
        currentComponent.setState({ overlay: true });
        var data = {
            filename: file[0],
            file: file[1]
        }
        this.props.downloadfile(data).then(function (result) {
            currentComponent.setState({ overlay: false });
        });

    }
    render() {
        return (<div className="row">
            <ControlledPopup open={this.state.overlay} />
            <div className="col-md-8">
                <div className="box box-primary">
                    <div className="box-header with-border">
                        <h3 className="box-title">Video</h3>
                    </div>
                    {/* /.box-header */}
                    <div className="box-body">
                        <dt>Bij Stichting Promotie Gemeente Epe zorgen zijn we actief bezig content te maken, zo ook video content. Via deze pagina zijn de verschillende video's te donwloaden. Let op de bestanden zijn erg groot.</dt>

                    </div>

                </div>

                <div className="box box-primary">
                    <div className="box-header with-border">
                        <h3 className="box-title">TimeLapse Epe</h3>
                    </div>
                    {/* /.box-header */}
                    <div className="box-body">
                        In 2019 hebben wij een timelapse laten maken over de de Gemeente Epe. Download hier de verschillende versies van deze film

            <div className="col-md-12 imageholder">
                            <img src="/100wild/video-final.jpg" alt="logo 100% wild" />
                        </div>

                    </div>
                    <div className="box-footer">
                        <button onClick={this.download.bind(this, ["Timelapse_VVV_Epe_Final_1080p.mp4", "Timelapse final"])} type="button" className="btn  btn-primary btn-sm">Download volledige versie</button>
                    </div>
                    {/* /.box-body */}
                </div>

                <div className="box box-primary">
                    <div className="box-header with-border">
                        <h3 className="box-title">Diverse variaties</h3>
                    </div>
                    {/* /.box-header */}
                    <div className="box-body">
                        Hieronder een overzicht van de twee versies. Beide versies duren 20 seconde en hebben hun eigen thema. Het eerste bestand gaat over de dorpen en het tweede bestand gaat over de natuur.
<                           div className="row">
                            <div className="col-md-6 imageholder">
                                <span className="label label-success pull-right">Versie over de dorpen</span>
                                <img src="/100wild/video-dorpen.jpg" alt="logo 100% wild" />
                                <button onClick={this.download.bind(this, ["Timelapse_VVV_Epe_Dorpen_20sec_1080p.mp4", "Timelapse dorpen 20 seconde"])} type="button" className="btn  btn-primary btn-sm">Download Bestand</button>
                            </div>
                            <div className="col-md-6 imageholder">
                                <span className="label label-success pull-right">Versie over de natuur</span>
                                <img src="/100wild/video-natuur.jpg" alt="logo 100% wild" />
                                <button onClick={this.download.bind(this, ["Timelapse_VVV_Epe_Landschappen_30sec_1080p.mp4", "Timelapse landschap 20 seconde"])} type="button" className="btn  btn-primary btn-sm">Download Bestand</button>
                            </div>
                        </div>

                    </div>

                    {/* /.box-body */}
                </div>
                {/* /.box */}
            </div>
            {/* ./col */}
            <div className="col-md-4">
                <div className="box box-solid">
                    {/* /.box-header */}
                    <div className="box-body imageholder">
                        <img src="/100wild/1x/Middel 1.png" alt="logo 100% wild" />
                    </div>
                    {/* /.box-body */}
                </div>
                {/* /.box */}
            </div>
            {/* ./col */}
        </div>);
    }
}
function mapStateToProps(state) {

}

export default connect(mapStateToProps, { downloadfile })(VideoBookStart);
