import React, { Component } from "react";
import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'
import LogoItem from "../Components/Toolbox/logo.jsx";
import SocialItem from "../Components/Toolbox/social.jsx";
import FotoItem from "../Components/Toolbox/fotoboek.jsx";
import ToolBoxItem from "../Components/Toolbox/toolbox.jsx";
import LedenWidget from "../Components/Widgets/leden.jsx";
import BeeldbankWidget from "../Components/Widgets/beeldbank.jsx";
import DownloadWidget from "../Components/Widgets/downloads.jsx";
class Startpage extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <LedenWidget />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <BeeldbankWidget />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <DownloadWidget />
        </div>

        <div className="col-md-3">
          <LogoItem />
        </div>
        <div className="col-md-3">
          <SocialItem />
        </div>
        <div className="col-md-3">
          <FotoItem />
        </div>
        <div className="col-md-3">
          <ToolBoxItem />
        </div>
        <div className="col-md-12">
      
        </div>
      </div>
    );
  }
}

export default Startpage;
