import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NewPassword,authError} from '../../reducers/auth/actions';
import {Link} from 'react-router-dom';
class NewPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: ''
    }
    this.onChange = this.onChange.bind(this);
  }
  userForget(e) {
    e.preventDefault();
    if (this.state.password === this.state.passwordcheck) {
      this.props.NewPassword({"code": this.props.match.params.code, "password": this.state.password}).then((whatever) => {
        if (whatever === true) {
          setTimeout(() => {
            this.props.history.push('/login');
          }, 3000);
        }
      });
    } else {
      this.props.authError("De wachtwoorden zijn niet gelijk aan elkaar");
    }
  }
  componentWillMount() {
    this.props.authError(null);
  }
  onChange(event) {
    this.props.authError(null);
    const field = event.target.name;
    const credentials = this.state;
    credentials[field] = event.target.value;
    return this.setState({credentials: credentials});
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (<div className="alert alert-danger">
        <strong>Oops!
        </strong>
        {this.props.errorMessage}
      </div>);
    }
  }

  renderOKAlert() {
    if (this.props.signupmsg) {
      return (<div className="alert alert-success">
        <strong>Super!
        </strong>
        {this.props.signupmsg}
      </div>);
    }
  }
  render() {
    return (<div className="content-page">
      <div className="content-wrapper fullscreen transpbackground">
        <div className="register-box">
          <div className="login-logo">
            <b>#EpeWild</b>
          </div>
          {/* /.login-logo */}
          <div className="login-box-body">
            <p className="login-box-msg">Kies een nieuw Wachtwoord</p>
            {this.renderAlert()}
            {this.renderOKAlert()}
            <form method="post">
              <div className="row-fluid">
                <div className="form-group has-feedback col-xs-6">
                  <input value={this.state.password} onChange={this.onChange} name="password" type="password" className="form-control" placeholder="Wachtwoord"/>
                  <span className="glyphicon glyphicon-lock form-control-feedback"/>
                </div>
                <div className="form-group has-feedback col-xs-6 ">
                  <input value={this.state.passwordcheck} onChange={this.onChange} name="passwordcheck" type="password" className="form-control" placeholder="Herhaal wachtwoord"/>
                  <span className="glyphicon glyphicon-log-in form-control-feedback"/>
                </div>
                <div className="col-xs-7 loginheight"></div>
                <div className="col-xs-5 loginheight">
                  <button onClick={(e) => this.userForget(e)} type="submit" className="btn btn-primary btn-block btn-flat">Verzenden</button>
                </div>
                <div className="span12">
                  <Link to="/login" className="text-center">Terug</Link>
                </div>
              </div>
            </form>
          </div>
          {/* /.form-box */}
        </div>
      </div>
      <footer className="main-footer fullscreen wild-logo ">
        <div className="footer-text pull-right hidden-xs">
          <b>Version</b>
          1.0.0
        </div>
        <strong>Copyright © 2018
          <a href="https://wwwappz.nl">WwappZ Studio</a>.</strong>
        All rights reserved.
      </footer>
    </div>);
  }
};
function mapStateToProps(state) {
  return {errorMessage: state.auth.error, signupmsg: state.auth.signupmsg};
}

export default connect(mapStateToProps, {NewPassword,authError})(NewPasswordPage);
