
import {
  AUTH_USER,
  AUTH_VERIFY,
  AUTH_ERROR,
  UNAUTH_USER,
  AUTH_USER_PROFILE,

} from './types';


function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    if (response.statusText ==="Unauthorized" ) {
      localStorage.removeItem('token');

    };
    let error = new Error(response.statusText);
    error.response = response;
    throw response;
  }
}


export function Login(data) {
  return dispatch => {
    return fetch('/api/signin', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_USER,
          user: data
        });
        localStorage.setItem('token', data.token);
        return true;
      }).catch(() => {
        dispatch(authError('Bad Login Info'));
      });
  }
}
export function loginforget(data) {
  return dispatch => {
    return fetch('/api/signin/forget', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_VERIFY,
          msg: data.msg
        });
        return true;
      }).catch(err =>
        dispatch(authError(err.statusText)));
  }
}
export function signupUser(data) {
  return dispatch => {
    return fetch('/api/signup', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_VERIFY,
          msg: data.msg
        });
        return true;
      }).catch(err =>
        dispatch(authError(err.statusText)));
  }
}
export function getprofile() {
  return dispatch => {
    return fetch('/api/user', {
        method: 'get',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_USER_PROFILE,
          user: data.user
        });
        return true;
      }).catch(err =>
        dispatch(authError("Om toegang te hebben moet je ingelogd zijn")));
  }
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}
export function signoutUser() {
  return dispatch => {
    localStorage.removeItem('token');
    return {
      type: UNAUTH_USER
    };
  }
}
export function confirmationPost(data) {
  return dispatch => {
    return fetch('/api/signup/verification/'+data.code, {
        headers: {
          "Content-Type": "application/json"
        }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_VERIFY,
          msg: data.msg
        });
        return true;
      }).catch(err =>
        dispatch(authError(err.statusText)));
  }
}

export function NewPassword(data) {
  return dispatch => {
    return fetch('/api/login/forget/verification', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
      }).then(handleResponse)
      .then(data => {
        dispatch({
          type: AUTH_VERIFY,
          msg: data.msg
        });
        return true;
      }).catch(err =>
        dispatch(authError(err.statusText)));
  }
}
