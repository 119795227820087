export const SET_LEDEN = 'SET_LEDEN';
export const SET_LID = 'SET_LID';
export const ADD_LEDEN = 'ADD_LEDEN';
export const TAGS_ERROR = 'TAGS_ERROR';
export const LEDEN_DELETED = 'LEDEN_DELETED';
export const SET_LEDEN_COUNT = 'SET_LEDEN_COUNT';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    console.log(response);
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_LEDEN,
    results
  }
}
export function setcount(results) {
  return {
    type: SET_LEDEN_COUNT,
    results
  }
}

export function setin(results) {
  return {
    type: SET_LID,
    results
  }
}

export function add(results) {
  return {
    type: ADD_LEDEN,
    results
  }
}
export function del(tagID) {
  return {
    type: LEDEN_DELETED,
    tagID
  }
}
export function authError(error) {
  return {
    type: TAGS_ERROR,
    payload: error
  };
}

export function fetchleden() {
  return dispatch => {
    fetch('/api/leden/', {
        headers: {
            "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(authError(err)));
  };
}
export function fetchfotosItem(item) {
  return dispatch => {
    fetch('/api/leden/'+item, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(setin(data.results)))
      .catch(err =>
        dispatch(authError(err)));
  };
}


export function deletelid(id) {
  return dispatch => {
    return fetch(`/api/leden/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
          authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(del(id)));
  }
}

export function fetchledencount() {
  return dispatch => {
    fetch('/api/leden/count', {
        headers: {
            "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(setcount(data)))
      .catch(err =>
        dispatch(authError(err)));
  };
}

export function saveleden(data) {
  return dispatch => {
    return fetch('/api/leden', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(add(data.results)));
  }
}
