import {
  SET_ACTIVITEITEN,
  ADD_ACTIVITEITEN,
} from './actions';

export default function cats(state = [], action = {}) {
  switch (action.type) {
    case ADD_ACTIVITEITEN:
      return [
        ...state,
        action.activieit
      ];

    case SET_ACTIVITEITEN:
      return action.activiteiten;
    default:
      return state;
  }
}
