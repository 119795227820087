export const SET_DOELGROEPEN = 'SET_DOELGROEPEN';
export const ADD_DOELGROEP = 'ADD_DOELGROEP';
export const DOELGROEP_FETCHED = 'DOELGROEP_FETCHED';
export const DOELGROEP_UPDATED = 'DOELGROEP_UPDATED';
export const DOELGROEP_DELETED = 'DOELGROEP_DELETED';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function setDoelgroep(doelgroepen) {
  return {
    type: SET_DOELGROEPEN,
    doelgroepen
  }
}

export function addDoelgroep(doelgroep) {
  return {
    type: ADD_DOELGROEP,
    doelgroep
  }
}

export function doelgroepFetched(doelgroep) {
  return {
    type: DOELGROEP_FETCHED,
    doelgroep
  }
}

export function doelgroepUpdated(doelgroep) {
  return {
    type: DOELGROEP_UPDATED,
    doelgroep
  }
}

export function doelgroepDeleted(doelgroepId) {
  return {
    type: DOELGROEP_DELETED,
    doelgroepId
  }
}

export function saveDoelgroep(data) {
  return dispatch => {
    return fetch('/api/doelgroepen', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(addDoelgroep(data.doelgroep)));
  }
}

export function updateDoelgroep(data) {
  return dispatch => {
    return fetch(`/api/doelgroepen/${data._id}`, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponse)
    .then(data => dispatch(doelgroepUpdated(data.doelgroep)));
  }
}

export function deleteDoelgroep(id) {
  return dispatch => {
    return fetch(`/api/doelgroepen/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
          authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(doelgroepDeleted(id)));
  }
}

export function fetchDoelgroepen() {
  return dispatch => {
    fetch('/api/doelgroepen',{
          headers: { authorization: localStorage.getItem('token') }
    })
      .then(res => res.json())
      .then(data => dispatch(setDoelgroep(data.doelgroepen)));
  }
}

export function fetchDoelgroep(id) {
  return dispatch => {
    fetch(`/api/doelgroepen/${id}`)
      .then(res => res.json())
      .then(data => dispatch(doelgroepFetched(data.doelgroep)));
  }
}
