import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchfotos, download, downloads} from '../../../reducers/fotoboek/fotos/actions.js';
import {setcollection} from '../../../reducers/fotoboek/collection/actions.js';

class Downloads extends React.Component {
  constructor() {
    super();
    this.state = {
      themes: [],
      tags: [],
      limit: 12,
      page: 1
    }
  }

  componentDidMount() {
    this.props.downloads();
  }
  Download = (item) => {
    this.props.download(item);
  }


  render() {
    const {fotos} = this.props;
    console.log(fotos);
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="box box-default">
              <div className="box-header with-border">
                <h3 className="box-title">Mijn Downloads</h3>
                <span className="pull-right"><Link to="/beeldbank" className="btn btn-primary"><i className="fa fa-camera " aria-hidden="true"></i> Beeldbank</Link> <Link to="/beeldbank/collections"  className="btn btn-primary"><i className="fa fa-heart " aria-hidden="true"></i> Mijn Collections</Link></span>

              </div>

            </div>
          </div>


        <div className="gal-container">
          {
            fotos.map((app) => (<div key={app._id} className="col-md-3 col-sm-6 co-xs-12 gal-item">
              <div className="box">
                <Link to={"/beeldbank/item/" + app._id}>
                  <img alt="Foto voor gebruik" src={app.thumbnail[0]['480']}/>
                </Link>
              </div>
            </div>))
          }
        </div>

      </div>);

  }
}
function mapStateToProps(state) {
  return {fotos: state.fotos.items, total: state.fotos.total}
}

export default connect(mapStateToProps, {fetchfotos,downloads, download, setcollection})(Downloads);
