import {SET_COLLECTIONS, SET_COLLECTION, ADD_COLLECTION , COLLECTION_DELETED,COLLECTION_COUNT} from './actions';

export default function tags(state = {
  item: [],
  items: [],
  total:[]
}, action = {}) {
  switch (action.type) {
    case ADD_COLLECTION :
      return [
        ...state,
        action.results
      ];
    case COLLECTION_DELETED:
      return state.filter(item => item._id !== action.tagID);
    case SET_COLLECTION:
      return {
        ...state,
        item: action.results
      };
      case COLLECTION_COUNT:
        return {
          ...state,
          total: action.results
        };
    case SET_COLLECTIONS:
      return {
        ...state,
        items: action.results.docs,
        total: action.results.total
      };
    default:
      return state;
  }
}
