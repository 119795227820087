import {
  SET_FOTOS,
  SET_FOTO,
  ADD_FOTOS,
  FOTOS_DELETED,
  FOTOS_COUNT,
  FOTOS_COUNT_DOWNLOAD,
} from "./actions";

export default function tags(
  state = {
    item: [],
    items: [],
    total: [],
    downtotal: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_FOTOS:
      return [...state, action.results];
    case FOTOS_DELETED:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.tagID),
      };
    case SET_FOTO:
      return {
        ...state,
        item: action.results,
      };
    case FOTOS_COUNT:
      return {
        ...state,
        total: action.results,
      };
    case SET_FOTOS:
      return {
        ...state,
        items: action.results.docs,
        total: action.results.total,
      };
    case FOTOS_COUNT_DOWNLOAD:
      return {
        ...state,
        downtotal: action.results,
      };
    default:
      return state;
  }
}
