import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import jwtDecode from "jwt-decode";
import rootReducer from './rootReducer';
import {Provider} from 'react-redux';
import {BrowserRouter, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
import {AUTH_TOKEN, AUTH_USER} from './reducers/auth/types';
import 'bootstrap/dist/js/bootstrap'; // for responsive navbar dropdown and modal dialog
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './lib/css/ADMStyle.css';
import './lib/css/skins/skin-blue.css';
import './index.css';
import './lib/css/style.css';

const store = createStore(rootReducer, applyMiddleware(thunk));
try {
  const token = jwtDecode(localStorage.getItem("token"));
  // update application state with token information if needed
  if (token) {
    store.dispatch({ type: AUTH_TOKEN, token: token });
    store.dispatch({ type: AUTH_USER });
  }
} catch (error) {
  console.log(error);
}
ReactGA.initialize('G-NTCDT8CNGC');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(<BrowserRouter>
  <Provider store={store}>
    <Switch>
      <App/>
    </Switch>
  </Provider>
</BrowserRouter>, document.getElementById('root'));
