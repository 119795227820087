import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../reducers/fotoboek/tags/actions.js';

class BedrijvenPagina extends React.Component {
  state = {
    errors: {},
    naam: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    const {naam} = this.state;
    this.props.save({naam}).then((data) => {
      if (data.errors) {
        errors.mes = "foutje";
        this.setState({errors});
        return true
      } else {
        this.props.history.replace({pathname: '/beeldbank/tags'})
      }
    });
  }

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Tags Toevoegen</h3>
          <div className="box-tools pull-right">
            <Link to="/fotoboek/tags/" type="button" className="btn-info btn btn-box-too btn-sm">
              Terug
            </Link>
            <button type="button" className="btn btn-box-tool" data-widget="collapse">
              <i className="fa fa-minus"></i>
            </button>
            <button type="button" className="btn btn-box-tool" data-widget="remove">
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>

        <div className="box-body">
          <form onSubmit={this.handleSubmit}>
            {!!this.state.errors.mes && <div className="callout callout-danger">
              <h4>Serer error</h4>
              <p>Dit was het bericht van de server
                <b>{this.state.errors.mes}</b>
              </p>
            </div>}
            <div className="form-group">
              <label htmlFor="titel">Naam Tags</label>
              <input type="text" className="form-control" value={this.state.naam} onChange={this.handleChange} name="naam" id="naam" placeholder="Vul de naam in"/>
            </div>
            <div className="box-footer">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>

        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {bedrijven: state.bedrijven}
}
export default connect(mapStateToProps, actions)(BedrijvenPagina);
