import React from 'react';


class TekstenPage extends React.Component {

  render() {
    return (<div className="row">
      <div className="col-md-9">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Standaard Teksten</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <dt>Hieronder treft u een aantal teksten aan die u kunt gebruiken om iets over Epe te vertellen met daarin verwerkt het toeristische profiel “100% Wildgarantie op de Veluwe”.
            </dt>
            <p className="text-green">De tekst kent drie lengte-varianten, zodat u altijd een geschikte variant vindt voor uw website, folder, brochure of op een andere plek waar u het profiel kunt ondersteunen en kunt laten zien dat u deel uitmaakt van de regio Epe.</p>
          </div>
          {/* /.box-body */}
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Teksten</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body darkgreenbackground">
            <div className="col-md-6">
              [kort]
              <h2>Epe 100% Wild</h2>
              Gemeente Epe is prachtig gelegen op de Veluwe! Epe vormt de ideale bestemming voor een vakantie of dagje uit met 100% wildgarantie. Zwijnen, herten, ijsvogels en dassen behoren tot de vaste bewoners van de directe omgeving van Epe. Ontdek het zelf tijdens een wildsafari of gewoon tijdens een mooie wandel- of fietstocht. Op een andere manier van Veluws wild genieten in Epe kan natuurlijk ook. Epe barst van de culinaire trekpleisters die tijdens het seizoen wild op een bijzondere manier op uw bord presenteren.
            </div>
            <div className="col-md-6">
              [Middel]
              <h2>Epe 100% Wild</h2>
              Gemeente Epe, met haar dorpen Epe, Vaassen, Emst en Oene, is prachtig gelegen op de Veluwe! Epe vormt de ideale bestemming voor een vakantie of dagje uit met 100% wildgarantie. Zwijnen, herten, ijsvogels en dassen behoren tot de vaste bewoners van de directe omgeving van Epe. Van de Big Five van Staatsbosbeheer komen maar liefst vier diersoorten voor in de gemeente Epe: edelherten, reeën, zwijnen en dassen! Je kan ze spotten in de schemering met een verrekijker. Ontdek het wild tijdens een wildsafari onder begeleiding van een gids; zeer avontuurlijk. Of juist veilig vanachter een wildschutting. Wild van heel dichtbij bewonderen kan in één van de wildweides. Of kies voor een van de vele mooie wandel- en fietsroutes. Op een andere manier van Veluws wild genieten kan natuurlijk ook. Epe barst van de culinaire trekpleisters die tijdens het seizoen wild op een bijzondere manier op uw bord presenteren. Van sterrenrestaurant tot eetcafé: het wild hoeft niet van ver te komen. En wist u dat de beste wildslager gevestigd is in de gemeente Epe?
            </div>
            <div className="col-md-6">
              [lang]
              <h2>Epe 100% Wild</h2>
              Gemeente Epe is prachtig gelegen in het noordoostelijke deel op de Veluwe! De gemeente bestaat uit de dorpen Epe, Emst, Oene en Vaassen, alle met hun eigen identiteit, maar alle vooral ook bekend om hun gastvrijheid en om het wild!
              <h3>De “Big Five” van de lage landen</h3>
              Epe vormt de ideale bestemming voor een vakantie of dagje uit. Je zal gegarandeerd wild zien! Epe geeft 100% wildgarantie. Zwijnen, herten, ijsvogels en dassen behoren tot de vaste bewoners van de directe omgeving van Epe. Van de Big Five van Staatsbosbeheer komen maar liefst vier diersoorten voor in de gemeente Epe: edelherten, reeën, zwijnen en dassen! Je kan ze spotten in de schemering met een verrekijker. Alleen zeehonden kan je niet op de Veluwe vinden… Ontdek het wild tijdens een wildsafari onder begeleiding van een gids; zeer avontuurlijk. Of juist veilig vanachter een wildschutting. Wild van heel dichtbij bewonderen kan in één van de wildweides. Of kies voor een van de vele mooie wandel- en fietsroutes.
              <h3>Wild eten</h3>
              Op een andere manier van Veluws wild genieten kan natuurlijk ook. Epe barst van de culinaire trekpleisters die tijdens het seizoen wild op een bijzondere manier op uw bord presenteren. Van gezellige bistro’s tot sfeervolle restaurants en leuke cafés waar het uitstekend vertoeven is. Culinaire hoogstandjes zijn ook te vinden in Vaassen. Veel van het culinair genot komt uit de regio. Van sterrenrestaurant tot eetcafé: het wild hoeft niet van ver te komen. En dat proef je! Wist u dat de beste wildslager gevestigd is in de gemeente Epe?
            </div>

          </div>
        </div>

      </div>
      {/* ./col */}
      <div className="col-md-3">
        <div className="box box-solid">
          {/* /.box-header */}
          <div className="box-body">
            <img src="/100wild/1x/Middel 1.png" alt="logo 100% wild"/>
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      {/* ./col */}
    </div>);
  }
}

export default TekstenPage;
