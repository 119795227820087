import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../reducers/fotoboek/fotos/actions.js';
class beeldbankWidget extends Component {
  componentDidMount() {
    this.props.fotocount();
  }
  render() {
    const {total} = this.props;
    return (
      <div className="small-box bg-aqua">
        <div className="inner">
          <h3>{total.count}</h3>
          <p>
            Beelden
          </p>
        </div>
        <div className="icon">
          <i className="fa fa-camera"></i>
        </div>
        <Link to="/beeldbank/fotoboek" className="small-box-footer">
        Ga naar de beeldbank <i className="fa fa-arrow-circle-right"/>
      </Link>
      </div>
  )
  }
}

function mapStateToProps(state) {
  return {total: state.fotos.total}
}

export default connect(mapStateToProps, actions)(beeldbankWidget);
