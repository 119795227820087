export const SET_THEMAS = 'SET_THEMAS';
export const ADD_THEMAS = 'ADD_THEMAS';
export const THEMAS_ERROR = 'THEMAS_ERROR';
export const THEMAS_DELETED = 'THEMAS_DELETED'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    console.log(response);
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_THEMAS,
    results
  }
}

export function add(results) {
  return {
    type: ADD_THEMAS,
    results
  }
}
export function del(tagID) {
  return {
    type: THEMAS_DELETED,
    tagID
  }
}
export function authError(error) {
  return {
    type: THEMAS_ERROR,
    payload: error
  };
}
export function save(data) {
  return dispatch => {
    return fetch('/api/fotoboek/themas', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      }).then(handleResponse)
      .then(data => dispatch(add(data)))
      .catch(err =>
        dispatch(authError(err)));
  };
}



export function fetchthemas() {
  return dispatch => {
    fetch('/api/fotoboek/themas', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(authError(err)));
  };
}

export function deletethema(id) {
  return dispatch => {
    return fetch(`/api/fotoboek/themas/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
          authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(del(id)));
  }
}
