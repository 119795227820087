import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../reducers/leden/actions.js';

class BedrijvenPagina extends React.Component {
  state = {
    errors: {},
    naam: '',
    contactpersoon: '',
    adres: '',
    postcode: '',
    plaats: '',
    email: '',
    type: 'verblijf',
    islid: false
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit = (e) => {
    console.log(this.state);
    e.preventDefault();
    let errors = {};
    const {
      naam,
      contactpersoon,
      adres,
      postcode,
      plaats,
      email,
      type,
      islid
    } = this.state;
    this.props.saveleden({
      naam,
      contactpersoon,
      adres,
      postcode,
      plaats,
      email,
      type,
      islid
    }).then((data) => {
      if (data.errors) {
        errors.mes = "foutje";
        this.setState({errors});
        return true
      } else {
        this.props.history.replace({pathname: '/leden'})
      }
    });
  }

  render() {
    return (<div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Leden Toevoegen</h3>
        <div className="box-tools pull-right">
          <Link to="/fotoboek/tags/" type="button" className="btn-info btn btn-box-too btn-sm">
            Terug
          </Link>
          <button type="button" className="btn btn-box-tool" data-widget="collapse">
            <i className="fa fa-minus"></i>
          </button>
          <button type="button" className="btn btn-box-tool" data-widget="remove">
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>

      <div className="box-body">
        <form onSubmit={this.handleSubmit}>
          {
            !!this.state.errors.mes && <div className="callout callout-danger">
                <h4>Serer error</h4>
                <p>Dit was het bericht van de server
                  <b>{this.state.errors.mes}</b>
                </p>
              </div>
          }
          <div className="form-group">
            <label htmlFor="titel">Naam</label>
            <input type="text" className="form-control" value={this.state.naam} onChange={this.handleChange} name="naam" id="naam" placeholder="Vul de naam in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Contactpersoon</label>
            <input type="text" className="form-control" value={this.state.contactpersoon} onChange={this.handleChange} name="contactpersoon" id="contactpersoon" placeholder="Vul de contactpersoon in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Adres</label>
            <input type="text" className="form-control" value={this.state.adres} onChange={this.handleChange} name="adres" id="adres" placeholder="Vul het adres in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Postcode</label>
            <input type="text" className="form-control" value={this.state.postcode} onChange={this.handleChange} name="postcode" id="postcode" placeholder="Vul de postcoe in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Plaats</label>
            <input type="text" className="form-control" value={this.state.plaats} onChange={this.handleChange} name="plaats" id="plaats" placeholder="Vul de plaats in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Email</label>
            <input type="email" className="form-control" value={this.state.email} onChange={this.handleChange} name="email" id="email" placeholder="Vul het emailadres in" required="required"/>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Type</label>
            <select className="form-control" value={this.state.type} onChange={this.handleChange} name="type" id="type" placeholder="Vul het type in" required="required">
              <option value="verblijf">verblijf</option>
            <option value="horeca">horeca</option>
            <option value="dagrecreatie">dagrecreatie</option>
            <option value="winkel">winkel</option>
          <option value="geen">geen</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="titel">Betalend lid</label>
            <select className="form-control" value={this.state.idlid} onChange={this.handleChange} name="islid" id="islid" placeholder="Is betalend lid?" required="required">
              <option value="false">nee</option>
              <option value="true">ja</option>
            </select>
          </div>
          <div className="box-footer">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>

      </div>
    </div>)
  }
}
function mapStateToProps(state) {
  return {}
}
export default connect(mapStateToProps, actions)(BedrijvenPagina);
