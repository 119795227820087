import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import  {getprofile, signoutUser} from '../../reducers/auth/actions';
import {fetchprofile} from '../../reducers/profile/actions.js';

class Header extends Component {
  componentWillMount() {
    this.props.getprofile();
    this.props.fetchprofile();
  }
  Logout() {
    this.props.signoutUser();
  }
  render() {
    const {username, profile} = this.props
    const Profileimage = (profile) => {
        if (profile) {
      return     <img src={profile.portimage }  className="img-circle" alt="Gebuiker foto"/>
      } else {
          return     <img src="/img/nofoto.jpg"  className="img-circle" alt="Gebuiker foto"/>
      }
    }
    return (
      <header className="main-header">
        {/* Logo */}
        <a href="/" className="logo">
          {/* mini logo for sidebar mini 50x50 pixels */}
          <span className="logo-mini">
            #<b>E</b>
          </span>
          {/* logo for regular state and mobile devices */}
          <span className="logo-lg">
            <b>#EpeWild</b>
          </span>
        </a>
        {/* Header Navbar: style can be found in header.less */}
        <nav className="navbar navbar-static-top">
          {/* Sidebar toggle button*/}
          <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">

                  <span className="hidden-xs">
                    {username.name}
                  </span>
                </a>
                <ul className="dropdown-menu">
                  {/* User image */}
                  <li className="user-header">
                    {Profileimage(profile)}
                    <p>

                    </p>
                  </li>
                  <li className="user-footer">
                    <div className="pull-left">
                      <Link to="/admin/profile" className="btn btn-default btn-flat">Profiel</Link>
                    </div>
                    <div className="pull-right">
                      <a href="/login" onClick={() => this.Logout()} className="btn btn-default btn-flat">Sign out</a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
};

function mapStateToProps(state) {
  return {username: state.auth.user, profile: state.profile};
}
export default connect(mapStateToProps, {fetchprofile, getprofile, signoutUser})(Header);
