  import React, {Component} from 'react';
class Footer extends Component{
  render (){
   return (

      <footer className="main-footer">
        <div className="pull-right hidden-xs">
          <b>Version</b> 1.0.0
        </div>
        <strong>Copyright © 2023 <a href="https://wwwappz.com">WwappZ Studio</a>.</strong> All rights
        reserved.
      </footer>
    );
  }
};

export default Footer;
