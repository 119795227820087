export const SET_ACTIVITEITEN = 'set_aciviteiten';
export const ADD_ACTIVITEITEN = 'add_aciviteiten';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function set(data) {
  return {
    type: SET_ACTIVITEITEN,
    data
  }
}

export function add(data) {
  return {
    type: ADD_ACTIVITEITEN,
    data
  }
}

export function save(data) {
  return dispatch => {
    return fetch('/api/activiteiten', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(add(data.activiteiten)));
  }
}

export function fetch() {
  return dispatch => {
    fetch('/api/activiteiten',{
          headers: { authorization: localStorage.getItem('token') }
    })
      .then(res => res.json())
      .then(data => dispatch(set(data.activiteiten)));
  }
}
