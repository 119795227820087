export const SET_UPLOAD = 'SET_UPLOAD';
export const ADD_UPLOAD = 'ADD_UPLOAD';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_DELETED = 'UPLOAD_DELETED'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    console.log(response);
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_UPLOAD,
    results
  }
}

export function add(results) {
  return {
    type: ADD_UPLOAD,
    results
  }
}
export function del(tagID) {
  return {
    type: UPLOAD_DELETED,
    tagID
  }
}
export function resultError(error) {
  return {
    type: UPLOAD_ERROR,
    payload: error
  };
}
export function upload(data) {
  return dispatch => {
    var formData = new FormData();
    formData.append('file', data.file);
    return fetch('/api/fotoboek/upload', {
      method: 'post',
      body: formData,
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(handleResponse)
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(resultError(err)));
  };
  }
