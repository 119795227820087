import React, {Component} from 'react'

class Loading extends Component {
  render() {
    return (
      <div className="callout callout-info">
        <h4>Loading</h4>
        <p>Er wordt hard gewerkt op je gegevens van het internet te halen</p>
      </div>
    )
  }
}

export default Loading;
