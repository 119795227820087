import React from "react";

export class ControlledPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    render() {
        var open = this.props.open
        if (open) {
            return (
                <div
                    className="overlay">
                    <div className="overlaytext"> <h1>Het bestand wordt gedownload</h1></div>


                </div>
            );
        } else
            return null
    }
}
