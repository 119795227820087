import {
  SET_THEMAS,
  ADD_THEMAS,
  THEMAS_DELETED
} from './actions';

export default function themas(state = [], action = {}) {
  switch (action.type) {
    case ADD_THEMAS:
      return [
        ...state,
        action.results
      ];

    case THEMAS_DELETED:
      return state.filter(item => item._id !== action.tagID);

    case SET_THEMAS:
      return action.results;
    default:
      return state;
  }
}
