import {
  SET_CATS,
  ADD_CAT,
  CAT_FETCHED,
  CAT_UPDATED,
  CAT_DELETED
} from './actions';

export default function cats(state = [], action = {}) {
  switch (action.type) {
    case ADD_CAT:
      return [
        ...state,
        action.cat
      ];

    case CAT_DELETED:
      return state.filter(item => item._id !== action.catId);

    case CAT_UPDATED:
      return state.map(item => {
        if (item._id === action.cat._id) return action.cat;
        return item;
      });

    case CAT_FETCHED:
      const index = state.findIndex(item => item._id === action.cat._id);
      if (index > -1) {
        return state.map(item => {
          if (item._id === action.cat._id) return action.cat;
          return item;
        });
      } else {
        return [
          ...state,
          action.cat
        ];
      }

    case SET_CATS:
      return action.cats;
    default:
      return state;
  }
}
