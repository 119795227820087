import FileDownload from "js-file-download";

export const SET_FOTOS = 'SET_FOTOS';
export const SET_FOTO = 'SET_FOTO';
export const ADD_FOTOS = 'ADD_FOTOS';
export const TAGS_ERROR = 'TAGS_ERROR';
export const FOTOS_DELETED = 'FOTOS_DELETED';
export const FOTOS_COUNT = 'FOTOS_COUNT';
export const FOTOS_COUNT_DOWNLOAD = 'FOTOS_COUNT_DOWNLOAD';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

function handleDownload(response) {
  if (response.ok) {
    return response.blob();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {type: SET_FOTOS, results}
}

export function setin(results) {
  return {type: SET_FOTO, results}
}

export function add(results) {
  return {type: ADD_FOTOS, results}
}
export function count(results) {
  return {type: FOTOS_COUNT, results}
}
export function del(tagID) {
  return {type: FOTOS_DELETED, tagID}
}
export function countdownloads(results) {
  return {type: FOTOS_COUNT_DOWNLOAD, results}
}
export function authError(error) {
  return {type: TAGS_ERROR, payload: error};
}

export function fetchfotos(data) {
  return dispatch => {
    fetch('/api/fotoboek/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(set(data.results))).catch(err => dispatch(authError(err)));
  };
}
export function fetchfotosItem(item) {
  return dispatch => {
    fetch('/api/fotoboek/' + item, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(setin(data.results))).catch(err => dispatch(authError(err)));
  };
}

export function updatefotoItem(data, item) {
  return dispatch => {
    return fetch('/api/fotoboek/update/' + item, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(setin(data.results))).catch(err => dispatch(authError(err)));
  };
}

export function deletetags(id) {
  return dispatch => {
    return fetch(`/api/fotoboek/tags/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(data => dispatch(del(id)));
  }
}

export function deletefoto(id) {
  return dispatch => {
    return fetch(`/api/fotoboek/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(data => dispatch(del(id)));
  }
}

export function fotocount() {
  return dispatch => {
    return fetch(`/api/fotoboek/count`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(data => {
      dispatch(count(data))
    }).catch(err => dispatch(authError(err.statusText)));
  };
}

export function download(item) {
  return dispatch => {
    return fetch(`/api/fotoboek/download/${item}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleDownload).then(function(blob) {
      FileDownload(blob, item + '.jpg');
    }).catch(() => {
      dispatch(authError('Bad Login Info'));
    });
  }
}

export function downloadfile(data) {
  return async dispatch => {
    try {
      const response = await fetch(`/api/download/`, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const blob = await handleDownload(response);
      return FileDownload(blob, data.filename);
      
    }
    catch (e) {
      return dispatch(authError(e));
    }
  }
}

export function downloads() {
  return dispatch => {
    fetch('/api/fotoboek/downloads/', {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(set(data))).catch(err => dispatch(authError(err)));
  };
}

export function downloadcount() {
  return dispatch => {
    return fetch('/api/fotoboek/downloads/count', {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then(res => res.json()).then(data => dispatch(countdownloads(data))).catch(err => dispatch(authError(err)));
  };
}
