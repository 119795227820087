export const SET_CATS = 'SET_CATS';
export const ADD_CAT = 'ADD_CAT';
export const CAT_FETCHED = 'CAT_FETCHED';
export const CAT_UPDATED = 'CAT_UPDATED';
export const CAT_DELETED = 'CAT_DELETED';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function setCat(cats) {
  return {
    type: SET_CATS,
    cats
  }
}

export function addCat(cat) {
  return {
    type: ADD_CAT,
    cat
  }
}

export function catFetched(cat) {
  return {
    type: CAT_FETCHED,
    cat
  }
}

export function catUpdated(cat) {
  return {
    type: CAT_UPDATED,
    cat
  }
}

export function catDeleted(catId) {
  return {
    type: CAT_DELETED,
    catId
  }
}

export function saveCat(data) {
  return dispatch => {
    return fetch('/api/cats', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      }
    }).then(handleResponse)
    .then(data => dispatch(addCat(data.cat)));
  }
}

export function updateCat(data) {
  return dispatch => {
    return fetch(`/api/cats/${data._id}`, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponse)
    .then(data => dispatch(catUpdated(data.cat)));
  }
}

export function deleteCat(id) {
  return dispatch => {
    return fetch(`/api/cats/${id}`, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
          authorization: localStorage.getItem('token') 
      }
    }).then(handleResponse)
    .then(data => dispatch(catDeleted(id)));
  }
}

export function fetchCats() {
  return dispatch => {
    fetch('/api/cats',{
          headers: { authorization: localStorage.getItem('token') }
    })
      .then(res => res.json())
      .then(data => dispatch(setCat(data.cats)));
  }
}

export function fetchCat(id) {
  return dispatch => {
    fetch(`/api/cats/${id}`)
      .then(res => res.json())
      .then(data => dispatch(catFetched(data.cat)));
  }
}
