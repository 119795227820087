import React from 'react';


class Kleurenpalet extends React.Component {

  render() {
    return (<div className="row">
      <div className="col-md-2">
        <div className="box box-solid">
          {/* /.box-header */}
          <div className="box-body">
            <img alt=" Logo 100% wild" src="/100wild/1x/Middel 1.png" />
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      <div className="col-md-10">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Kleurenpalet</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <dt>Kleur brengt deze huisstijl tot leven en om als een eenheid naar buiten te treden is het belangrijk dat deze kleuren niet telkens afwijken. Om hier consistent in te zijn hebben wij in het kleurenpalet alles op een rijtje gezet.</dt>
            <p className="text-green">Het logo van “EPE 100% WILD” is een full colour logo bestaande uit 3 kleuren. Deze drie kleuren vormen de basis van het kleurenpalet.</p>
          </div>
          {/* /.box-body */}
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">CMYK kleuren (full colour)</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">Te gebruiken bij digitaal en offset drukwerk.s Denk aan advertenties, posters, flyers, brochurs, enz.
            <div className="col-md-12">
              <p>
                <i className="fa fa-circle fa-lg blue kleur" aria-hidden="true"></i>
                C 51 M 0 Y 0 K 0
              </p>
              <p>
                <i className="fa fa-circle fa-lg green kleur" aria-hidden="true"></i>
                C 75 M 0 Y 100 K 0</p>
              <p>
                <i className="fa fa-circle fa-lg darkgreen kleur" aria-hidden="true"></i>
                C 80 M 42 Y 82 K 44</p>
            </div>
          </div>
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">RGB kleuren (web)</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">Voor online gebruik, denk aan social media en websites.
            <div className="col-md-12">
              <p>
                <i className="fa fa-circle fa-lg blue kleur" aria-hidden="true"></i>
                #7fcff5
              </p>
              <p>
                <i className="fa fa-circle fa-lg green kleur" aria-hidden="true"></i>
                #3aaa35</p>
              <p>
                <i className="fa fa-circle fa-lg darkgreen kleur" aria-hidden="true"></i>
                #2f5234</p>
            </div>
          </div>
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">PMS kleuren</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">Voor het geval dat full colour niet mogelijk is en er in PMS gedrukt moet worden. Dit kan voorkomen bij foliedruk of belettering.
            <div className="col-md-12">
              <p>
                <i className="fa fa-circle fa-lg blue kleur" aria-hidden="true"></i>
                PMS 297
              </p>
              <p>
                <i className="fa fa-circle fa-lg green kleur" aria-hidden="true"></i>
                PMS 369</p>
              <p>
                <i className="fa fa-circle fa-lg darkgreen kleur" aria-hidden="true"></i>
                PMS 554</p>
            </div>
          </div>
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Aanvulling op het kleurenpalet</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <div className="col-md-6">
              Bij het ontwikkelen van diverse middelen (denk aan brochures, advertenties, flyers..enz) wordt het kleurenpalet uitgebreid met een aantal kleuren. Deze kleuren worden toegevoegd voor het commerciële aspect. Ter ondersteuning van het doel kunnen deze kleuren gebruikt worden.
            </div>
            <div className="col-md-6">
              <p>
                <i className="fa fa-circle fa-lg rood kleur" aria-hidden="true"></i>
                Rood: C 0 M 100 Y 65 k 0
              </p>
              <p>
                <i className="fa fa-circle fa-lg roze kleur" aria-hidden="true"></i>
                Roze: C 0 M 80 Y 0 k 0</p>
              <p>
                <i className="fa fa-circle fa-lg bruin kleur" aria-hidden="true"></i>
                Bruin: C 40 M 70 Y 100 k 50</p>
              <p>
                <i className="fa fa-circle fa-lg oranje kleur" aria-hidden="true"></i>
                Oranje: C 0 M 40 Y 100 k 0</p>
            </div>
          </div>
        </div>
      </div>

    </div>);
  }
}

export default Kleurenpalet;
