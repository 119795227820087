import React from 'react';
import {connect} from 'react-redux';
import {downloadfile} from '../../reducers/fotoboek/fotos/actions.js';
class LogoStart extends React.Component {

download(file, e) {
  var data = {
    filename: file[0],
    file: file[1]
  }
  this.props.downloadfile(data);

}
  render() {
    return (<div className="row">
      <div className="col-md-8">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Logo</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <dt>Zwijnen, herten, ijsvogels en dassen behoren tot de vaste bewoners van de directe omgeving van Epe. Deze dieren zijn om die reden terug te vinden in het logo voor “Epe 100% Wild”. Een herkenbaar beeldmerk, met de nadruk op wild en natuur.</dt>
            <p className="text-green">Het logo van “EPE 100% WILD” is in basis een full colour logo bestaande uit 3 kleuren.</p>
          </div>
          <div className="box-footer">
            <button onClick={this.download.bind(this,["Logo.zip", "Logo Bestanden"])} type="button" className="btn  btn-primary btn-sm">Download Bestanden</button>
          </div>
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Subtitel</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            Het logo mag gebruikt worden met de subregel: “Gemeeente Epe 100% wildgarantie op de Veluwe.” Dit ziet er bij voorkeur zo uit:

            <div className="col-md-4">
              <img src="/100wild/Epe-wilgarantie-logo-metsub-links.png" alt="Logo 100% wild"/>
            </div>
            <div className="col-md-4">
              <img src="/100wild/Epe-wilgarantie-logo-metsub-rechts.png" alt="Logo 100% wild"/>
            </div>

          </div>
          <div className="box-footer">
            <button onClick={this.download.bind(this,["Logo met subregel.zip", "Logo met subregel"])} type="button" className="btn  btn-primary btn-sm">Download Bestanden</button>
          </div>
          {/* /.box-body */}
        </div>

        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Diverse variaties</h3>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            Hieronder een overzicht van de diverse variaties van het logo. Deze variaties zijn ontworpen omdat het gebruik van het full colour logo (voorkeur) niet altijd mogelijk is i.v.m. de verschillende druktechnieken van de media uitingen.

            <div className="col-md-3 imageholder">
              <span className="label label-success pull-right">Voorkeur</span>
              <img src="/100wild/Epe-Wildgarantie-Logo-POS-FC-RGB.png" alt="logo 100% wild"/>
            </div>
            <div className="col-md-3 imageholder">
              <img src="/100wild/Epe-Wildgarantie-Logo-DIAP-FC-01.png" alt="logo 100% wild"/>
            </div>
            <div className="col-md-3 imageholder">
              <img src="/100wild/GemeenteEpe-Wildgarantie-Logo-zw-w-DIAP-01.png" alt="logo 100% wild"/>
            </div>
            <div className="col-md-3 imageholder">
              <img src="/100wild/GemeenteEpe-Wildgarantie-Logo-zw-w-POS.png" alt="logo 100% wild"/>
            </div>

          </div>
          <div className="box-footer">
            <button onClick={this.download.bind(this,["variaties.zip", "Logo variaties"])} type="button" className="btn  btn-primary btn-sm">Download Bestanden</button>
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      {/* ./col */}
      <div className="col-md-4">
        <div className="box box-solid">
          {/* /.box-header */}
          <div className="box-body imageholder">
            <img src="/100wild/1x/Middel 1.png" alt="logo 100% wild" />
          </div>
          {/* /.box-body */}
        </div>
        {/* /.box */}
      </div>
      {/* ./col */}
    </div>);
  }
}
function mapStateToProps(state) {

}

export default connect(mapStateToProps, {downloadfile})(LogoStart);
