import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../reducers/fotoboek/fotos/actions.js';

class DownloadWidget extends Component {
  componentWillMount() {
    this.props.downloadcount();
  }
  render() {
      const {downtotal} = this.props;
    return (<div className="small-box bg-aqua">
      <div className="inner">
        <h3>{downtotal.count}</h3>
        <p>
          Downloads
        </p>
      </div>
      <div className="icon">
        <i className="fa fa-download"></i>
      </div>
      <Link to="/beeldbank/fotoboek" className="small-box-footer">
      Ga naar de beeldbank <i className="fa fa-arrow-circle-right"/>
    </Link>
    </div>
  )
  }
}

function mapStateToProps(state) {
  return {downtotal: state.fotos.downtotal}
}

export default connect(mapStateToProps, actions)(DownloadWidget);
