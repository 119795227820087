import React, {Component} from 'react';

import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../reducers/bedrijven/actions';
class Sitebar extends Component {
  componentDidMount() {
    this.props.getfavo();
  }

  render() {
    const {username, profile, token} = this.props;
    const profileimage = (profile) => {
      if (profile) {
        return <img src={profile.portimage}  className="img-circle" alt="logo 100% wild"/>
      } else {
        return <img src="/img/nofoto.jpg" className="img-circle" alt="logo 100% wild"/>
      }
    }

    const beeldbankadmin = () => {
      if (token.role === "admin") {
        return (<li className="treeview">
          <a href="#">
            <i className="fa fa-dashboard"/>
            <span>Administratie</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right"/>
            </span>
          </a>
          <ul className="treeview-menu">
            <li>
              <Link to="/leden">
                <i className="fa fa-book"></i>
                <span>Leden</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/beeldbank">
                <i className="fa fa-book"></i>
                <span>Beeldbank admin</span>
              </Link>
              <Link to="/beeldbank/upload">
                <i className="fa fa-book"></i>
                <span>Foto toevoegen</span>
              </Link>
            </li>
            <li>
              <Link to="/beeldbank/tags">
                <i className="fa fa-book"></i>
                <span>Foto tags</span>
              </Link>
            </li>
            <li>
              <Link to="/beeldbank/themas">
                <i className="fa fa-book"></i>
                <span>Foto thema</span>
              </Link>
            </li>
          </ul>
        </li>)
      }
    }
    return (<aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div className="pull-left image">
            {profileimage(profile)}
          </div>
          <div className="pull-left info">
            <p>
              {username.name}
            </p>
          </div>
        </div>
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu" data-widget="tree">
          <li className="header">HOOFDMENU</li>
          <li className="treeview">
            <a href="#">
              <i className="fa fa-dashboard"/>
              <span>Toolkits</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"/>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/toolkit/logo">
                  <i className="fa fa-book"></i>
                  <span>Logo</span>
                </Link>
              </li>
              <li>
                <Link to="/toolkit/kleurenpalet">
                  <i className="fa fa-book"></i>
                  <span>Kleurenpalet</span>
                </Link>
              </li>
              <li>
                <Link to="/toolkit/social">
                  <i className="fa fa-book"></i>
                  <span>Social Media</span>
                </Link>
              </li>
              <li>
                <Link to="/toolkit/teksten">
                  <i className="fa fa-book"></i>
                  <span>Standaard Teksten</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a href="#">
              <i className="fa fa-camera-retro"/>
              <span>Beeldbank</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"/>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/beeldbank/foto">
                  <i className="fa fa-book"></i>
                  <span>Beeldbank foto's</span>
                </Link>
              </li>
              <li>
                <Link to="/beeldbank/video">
                  <i className="fa fa-book"></i>
                  <span>Beeldbank video's</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="treeview">
            <a href="#">
              <i className="fa fa-download"/>
              <span>Epe Wild</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"/>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/epewild/jaarplan">
                  <i className="fa fa-book"></i>
                  <span>Jaarplan</span>
                </Link>
              </li>
              <li>
                <Link to="/epewild/bijeenkomsten">
                  <i className="fa fa-book"></i>
                  <span>Bijeenkomsten</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/leden">
              <i className="fa fa-book"></i>
              <span>Leden</span>
            </Link>
          </li>
          {beeldbankadmin()}
        </ul>
      </section>
      {/* /.sidebar */}
    </aside>);
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, token: state.auth.token, bedrijven: state.bedrijven.item, profile: state.profile};
}

export default connect(mapStateToProps, actions)(Sitebar);
