import {
  SET_TAGS,
  ADD_TAGS,
  TAGS_DELETED
} from './actions';

export default function tags(state = [], action = {}) {
  switch (action.type) {
    case ADD_TAGS:
      return [
        ...state,
        action.results
      ];

    case TAGS_DELETED:
      return state.filter(item => item._id !== action.tagID);

    case SET_TAGS:
      return action.results;
    default:
      return state;
  }
}
