import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchfotos,
  download,
} from "../../../reducers/fotoboek/fotos/actions.js";
import { setcollection } from "../../../reducers/fotoboek/collection/actions.js";
import Tags from "../../../Components/Fotoboek/form/tags.jsx";
import Themas from "../../../Components/Fotoboek/form/themas.jsx";
import Pagination from "../../..//Components/Main/Pagination.jsx";
class Fotos extends React.Component {
  constructor() {
    super();
    this.state = {
      themes: [],
      tags: [],
      limit: 12,
      page: 1,
    };
  }

  GetTags = (event) => {
    this.setState(
      {
        tags: event,
      },
      () => {
        this.refrechfoto();
      }
    );
  };

  GetTheme = (event) => {
    this.setState(
      {
        themes: event,
      },
      () => {
        this.refrechfoto();
      }
    );
  };
  SetPageNumber = (event) => {
    this.setState(
      {
        page: event,
      },
      function () {
        this.refrechfoto();
      }
    );
  };

  componentWillMount() {
    this.refrechfoto();
  }

  refrechfoto = () => {
    const { themes, tags, limit, page } = this.state;
    this.props.fetchfotos({ themes, tags, limit, page });
  };

  render() {
    const { fotos, total } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Administratie</h3>
            </div>
            <div className="box-body">
              <div className="col-md-4">
                <Tags name={this.state.tags} GetTags={this.GetTags} />
              </div>
              <div className="col-md-4">
                <Themas name={this.state.themes} GetTheme={this.GetTheme} />
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <div className="box box-solid">
            <div className="box-header with-border">
              <Pagination
                limit={this.state.limit}
                page={this.state.page}
                total={total}
                SetPageNumber={this.SetPageNumber}
              />
            </div>
          </div>
        </div>

        <div className="gal-container">
          {fotos.map((app) => (
            <div key={app._id} className="col-md-3 col-sm-6 co-xs-12 gal-item">
              <div className="box">
                <Link to={"/admin/beeldbank/item/" + app._id}>
                  <img
                    class="img-fluid img-thumbnail"
                    alt="Foto van Epe"
                    src={app.thumbnail[0]["480"]}
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { fotos: state.fotos.items, total: state.fotos.total };
}

export default connect(mapStateToProps, {
  fetchfotos,
  download,
  setcollection,
})(Fotos);
