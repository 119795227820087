import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class FotoItem extends Component {
  render() {
    return (

      <div className="box box-widget">
        <div className="box-header with-border">
          <div className="user-block">
            <span className="username">
              Beeldbank
            </span>
            <span className="description">
            Deze foto's zijn gartis te gebruiken
            </span>
          </div>
          {/* /.user-block */}
          <div className="box-tools">
            <button type="button" className="btn btn-box-tool" data-toggle="tooltip" title="title" data-original-title="Mark as read">
              <i className="fa fa-circle-o"/>
            </button>
            <button type="button" className="btn btn-box-tool" data-widget="collapse">
              <i className="fa fa-minus"/>
            </button>
            <button type="button" className="btn btn-box-tool" data-widget="remove">
              <i className="fa fa-times"/>
            </button>
          </div>
          {/* /.box-tools */}
        </div>
        {/* /.box-header */}
        <div className="box-body">
          <img className="img-responsive pad" src="/100wild/1x/Middel 1.png" alt="Portfolio foto"/>
          <p>
            Klik hier voor de beeldbank
          </p>
          <Link to="/beeldbank" className="btn btn-default btn-x">
            Meer informatie
          </Link>
        </div>
      </div>
  )
  }
}
export default FotoItem;
