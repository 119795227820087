import {
  SET_DOELGROEPEN,
  ADD_DOELGROEP,
  DOELGROEP_FETCHED,
  DOELGROEP_UPDATED,
  DOELGROEP_DELETED
} from './actions';

export default function doelgroepen(state = [], action = {}) {
  switch (action.type) {
    case ADD_DOELGROEP:
      return [
        ...state,
        action.doelgroep
      ];

    case DOELGROEP_DELETED:
      return state.filter(item => item._id !== action.doelgroepId);

    case DOELGROEP_UPDATED:
      return state.map(item => {
        if (item._id === action.doelgroep._id) return action.doelgroep;
        return item;
      });

    case DOELGROEP_FETCHED:
      const index = state.findIndex(item => item._id === action.doelgroep._id);
      if (index > -1) {
        return state.map(item => {
          if (item._id === action.doelgroep._id) return action.doelgroep;
          return item;
        });
      } else {
        return [
          ...state,
          action.doelgroep
        ];
      }

    case SET_DOELGROEPEN:
      return action.doelgroepen;
    default:
      return state;
  }
}
