import React, { Component } from "react";

class ErrorWidget extends Component {
  componentDidMount() {
    console.log(this.props);
  }
  render() {
    const { message } = this.props;
    return (
      <div className="card-body">
      <div className="alert alert-warning alert-dismissible">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        <h5>
          <i className="icon fa fa-warning" /> Alert!
        </h5>
      {message}
      </div>
      </div>
    );
  }
}

export default ErrorWidget;
